import './index.scss';
import LogoS from '../../../assets/images/plogo-s.png';



const Logo = () => {

    return (
        <div className='logo-container'>
            <img className='solid-logo' src={LogoS} alt="P"/>
                
        </div>
    )
}

export default Logo